#jobs .job {
  border: transparent solid 1px;
  padding: 10px;
  margin-bottom: 24px;

  &:hover {
    border-color: #ddd;
  }

  &-name {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;

    .opt {
      button {
        margin: 0 6px;
        opacity: 0;
      }
    }
  }

  &:hover {
    border-color: #ddd;

    .job-name .opt button {
      opacity: 1;
    }
  }

  &.hidden-job {
    background-color: #eee;
    opacity: 0.6;
  }

  h2 {
    font-weight: 600;
    font-size: 16px;
    margin: 24px 0;
  }

  .rows {
    ul li {
      margin-bottom: 12px;
    }
  }
}

#jobs .new-job {
  button {
    width: 100%;
    padding: 16px 0;
    height: auto;
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  @import './style.m.scss';
}
