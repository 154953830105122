#login {
  .form {
    width: 300px;
    margin: auto;

    &>div {
      margin: 12px;
    }
    &>div>* {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  @import './style.m.scss';
}
