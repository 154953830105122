#projects .project-group {
  padding: 12px;
  margin-bottom: 40px;
  border: transparent solid 1px;

  .group-name {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;

    .opt {
      button {
        margin: 0 6px;
        opacity: 0;
      }
    }
  }
  .group-items {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    row-gap: 12px;
  }
  &:hover {
    border-color: #ddd;

    .group-name .opt button {
      opacity: 1;
    }
  }

  &.hidden-group {
    background-color: #eee;
    opacity: 0.6;
  }
  &.swap-mode .project:hover .project-logo::before {
    opacity: 1;
  }
}

#projects .project {
  min-width: 0;
  width: 100%;
  text-align: center;

  &-logo {
    position: relative;
    height: 100px;
    margin: 0 12px 12px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -10px;
      height: 100%;
      width: 2px;
      background-color: red;
      opacity: 0;
    }

    .order-btn {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      opacity: 0;
      transition: all ease 0.2s;
      z-index: 2;

      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
      &.center {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    img {
      height: 100px;
      width: 100px;
      object-fit: contain;
    }

    .hidden-mask {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100px;
      height: 100px;
      transform: translate(-50%, 0);
      background: rgba(0, 0, 0, 0.4);
      text-align: center;
      font-size: 40px;
      line-height: 100px;
      color: #fff
    }

    .no-link {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &-name {
    font-weight: 600;
    margin-bottom: 12px;
  }
  &-opt {
    transition: all ease 0.2s;
    opacity: 0;

    button {
      margin-right: 6px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &:hover {
    .project-opt {
      opacity: 1;
    }
    .order-btn {
      opacity: 1;
    }
  }

  &.new .project-logo {
    button {
      width: 100px;
      height: 100%;
      font-size: 40px;
      color: #ddd;

      &:hover {
        color: inherit;
      }
    }
  }
}

#projectModal {
  .project-tags {
    .tag-input {
      width: 78px;
      margin-right: 8px;
      vertical-align: top;
    }

    .create-btn {
      background-color: transparent;
      border-style: dashed;
    }
  }
}

@media screen and (max-width: 600px) {
  @import './style.m.scss';
}
