#pagehtml {
  .tt {
    display: flex;
    justify-content: space-between;
  }
  .input {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 600px) {
  @import './style.m.scss';
}
